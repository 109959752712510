const static_market_page = {
    gold: {
        title: 'نرخ طلا',
        items: [
            'mesghal',
            'geram18',
            'geram24',
            'sekee',
            'sekeb',
            'nim',
            'rob',
            'gerami',
            'ons',
            'platinum',
            'palladium',
            'silver',
        ]
    },
    currency: {
        title: "نرخ ارز",
        items: [
        // 'price_dollar_rl',
        // 'price_eur',
        'price_gbp',
        // 'price_aed',
        'price_try',
        'price_cny',
        'price_jpy',
        'price_cad',
        'price_aud',
        'price_nzd',
        'price_chf',
        'price_afn',
        'price_sek',
        'price_dkk',
        'price_nok',
        'price_kwd',
        'price_sar',
        'price_qar',
        'price_omr',
        'price_iqd',
        'price_bhd',
        'price_syp',
        'price_inr',
        'price_pkr',
        'price_sgd',
        'price_hkd',
        'price_myr',
        'price_thb',
        'price_rub',
        'price_azn',
        'price_amd',
        'price_gel',
        ]
    },
    coin: {
        title: 'سکه',
        items: [
            'sekee',
            'sekeb',
            'nim',
            'rob',
            'gerami',
        ]
    },
    cryptocurrency: {
        title: 'ارز دیجیتال',
        items: [
            'crypto-bitcoin',
            'crypto-ethereum',
            'crypto-tether',
            'crypto-ripple',
            'crypto-dash',
            'crypto-litecoin',
            'crypto-cardano',
            'crypto-binance-coin',
            'crypto-stellar',
            'crypto-dogecoin',
            'crypto-bitcoin-cash',
            'crypto-usd-coin',
            'crypto-tron',
            'crypto-monero',
            'crypto-chainlink',
        ]
    },
    bank: {
        title: 'ارز مبادله‌ای',
        items: [
            'bank_usd',
            'bank_eur',
            'bank_gbp',
            'bank_aed',
            'bank_try',
            'bank_cny',
            'bank_jpy',
            'bank_cad',
            'bank_aud',
            'bank_chf',
            'bank_afn',
            'bank_sek',
            'bank_dkk',
            'bank_nok',
            'bank_kwd',
            'bank_sar',
            'bank_qar',
            'bank_omr',
            'bank_iqd',
            'bank_bhd',
            'bank_syp',
            'bank_inr',
            'bank_pkr',
            'bank_sgd',
            'bank_hkd',
            'bank_myr',
            'bank_thb',
            'bank_rub',
            'bank_azn',
            'bank_amd',
        ]
    },
    energy: {
        title: 'نفت و انرژی',
        items: [
            'oil',
            'oil_brent',
            'oil_opec',
            'general_9',
            'general_10',
            'general_11',
        ]
    },
    'precious-metals': {
        title: 'فلزات گرانبها',
        items: [
            'platinum',
            'palladium',
        ]
    },
    'base-metals': {
        title: 'فلزات پایه',
        items: [
            'general_3',
            'general_4',
            'general_5',
            'general_6',
            'general_7',
            'cobalt',
        ]
    },
    global: {
        title: 'شاخص‌های جهانی',
        items: [
            'ons',
            'platinum',
            'palladium',
            'silver',
            'general_3',
            'general_4',
            'general_5',
            'general_6',
            'general_7',
            'cobalt',
            'oil',
            'oil_brent',
            'oil_opec',
            'general_9',
            'general_10',
            'general_11',
            'general_12',
            'general_13',
            'general_14',
            'general_15',
            'general_16',
        ]
    },
    sana: {
        title: 'ارز سنا',
        items: [
            'sana_buy_usd',
            'sana_sell_usd',
            'sana_buy_eur',
            'sana_sell_eur',
            'sana_buy_aed',
            'sana_sell_aed',
            'sana_buy_gbp',
            'sana_sell_gbp',
            'sana_buy_chf',
            'sana_sell_chf',
            'sana_buy_sek',
            'sana_sell_sek',
            'sana_buy_nok',
            'sana_sell_nok',
            'sana_buy_inr',
            'sana_sell_inr',
            'sana_buy_jpy',
            'sana_sell_jpy',
            'sana_buy_cad',
            'sana_sell_cad',
            'sana_buy_try',
            'sana_sell_try',
            'sana_buy_rub',
            'sana_sell_rub',
            'sana_buy_iqd',
            'sana_sell_iqd',
            'sana_buy_aud',
            'sana_sell_aud',
            'sana_buy_cny',
            'sana_sell_cny',
            'sana_buy_krw',
            'sana_sell_krw',
        ]
    },
    diff: {
        title: 'برابری ارزها',
        items: [
            'diff_eur_usd',
            'diff_gbp_usd',
            'diff_usd_jpy',
            'diff_usd_chf',
            'diff_aud_usd',
            'diff_usd_cad',
            'diff_nzd_usd',
            'diff_usd_zar',
            'diff_usd_try',
            'diff_btc_usd',
            'diff_usd_mxn',
            'diff_usd_pln',
            'diff_usd_sek',
            'diff_usd_sgd',
            'diff_usd_dkk',
            'diff_usd_nok',
            'diff_usd_huf',
            'diff_usd_czk',
            'diff_usd_thb',
            'diff_usd_aed',
            'diff_usd_jod',
            'diff_usd_kwd',
            'diff_usd_hkd',
            'diff_usd_sar',
            'diff_usd_inr',
            'diff_usd_krw',
            'diff_brl_usd',
            'diff_cad_usd',
            'diff_chf_usd',
            'diff_fjd_usd',
            'diff_ghs_usd',
            'diff_jpy_usd',
            'diff_kyd_usd',
            'diff_sgd_usd',
            'diff_usd_all',
            'diff_usd_amd',
            'diff_usd_ang',
            'diff_usd_ars',
            'diff_usd_aud',
            'diff_usd_bbd',
            'diff_usd_bdt',
            'diff_usd_bgn',
            'diff_usd_bhd',
            'diff_usd_bif',
            'diff_usd_bnd',
            'diff_usd_bob',
            'diff_usd_brl',
            'diff_usd_bsd',
            'diff_usd_bwp',
            'diff_usd_bzd',
            'diff_usd_clp',
            'diff_usd_cny',
            'diff_usd_cop',
            'diff_usd_crc',
            'diff_usd_cup',
            'diff_usd_djf',
            'diff_usd_dop',
            'diff_usd_dzd',
            'diff_usd_egp',
            'diff_usd_etb',
            'diff_usd_eur',
            'diff_usd_fjd',
            'diff_usd_gbp',
            'diff_usd_gel',
            'diff_usd_ghs',
            'diff_usd_gmd',
            'diff_usd_gnf',
            'diff_usd_gtq',
            'diff_usd_hnl',
            'diff_usd_hrk',
            'diff_usd_htg',
            'diff_usd_idr',
            'diff_usd_iqd',
            'diff_usd_isk',
            'diff_usd_jmd',
            'diff_usd_kes',
            'diff_usd_khr',
            'diff_usd_kmf',
            'diff_usd_kzt',
            'diff_usd_lak',
            'diff_usd_lbp',
            'diff_usd_lkr',
            'diff_usd_lsl',
            'diff_usd_ltl',
            'diff_usd_lyd',
            'diff_usd_mad',
            'diff_usd_mdl',
            'diff_usd_mga',
            'diff_usd_mkd',
            'diff_usd_mmk',
            'diff_usd_mop',
            'diff_usd_mro',
            'diff_usd_mur',
            'diff_usd_mvr',
            'diff_usd_mwk',
            'diff_usd_myr',
            'diff_usd_nad',
            'diff_usd_ngn',
            'diff_usd_nio',
            'diff_usd_npr',
            'diff_usd_nzd',
            'diff_usd_omr',
            'diff_usd_pab',
            'diff_usd_pen',
            'diff_usd_pgk',
            'diff_usd_php',
            'diff_usd_pkr',
            'diff_usd_pyg',
            'diff_usd_qar',
            'diff_usd_ron',
            'diff_usd_rsd',
            'diff_usd_rub',
            'diff_usd_rwf',
            'diff_usd_scr',
            'diff_usd_sdg',
            'diff_usd_sos',
            'diff_usd_std',
            'diff_usd_svc',
            'diff_usd_syp',
            'diff_usd_szl',
            'diff_usd_tnd',
            'diff_usd_ttd',
            'diff_usd_twd',
            'diff_usd_tzs',
            'diff_usd_uah',
            'diff_usd_ugx',
            'diff_usd_uyu',
            'diff_usd_uzs',
            'diff_usd_vef',
            'diff_usd_vnd',
            'diff_usd_vuv',
            'diff_usd_xaf',
            'diff_usd_xcd',
            'diff_usd_xof',
            'diff_usd_xpf',
            'diff_usd_yer',
            'diff_usd_zmk',
            'diff_aed_usd',
            'diff_ars_usd',
            'diff_cny_usd',
            'diff_dkk_usd',
            'diff_hkd_usd',
            'diff_mxn_usd',
            'diff_nok_usd',
            'diff_pln_usd',
            'diff_rub_usd',
            'diff_sar_usd',
            'diff_sek_usd',
            'diff_try_usd',
            'diff_twd_usd',
            'diff_vef_usd',
            'diff_zar_usd',
            'diff_uyu_usd',
            'diff_pyg_usd',
            'diff_clp_usd',
            'diff_cop_usd',
            'diff_pen_usd',
            'diff_nio_usd',
            'diff_bob_usd',
            'diff_krw_usd',
            'diff_egp_usd',
            'diff_ltc_usd',
            'diff_usd_byn',
            'diff_usd_mzn',
            'diff_inr_usd',
            'diff_jod_usd',
            'diff_kwd_usd',
            'diff_usd_azn',
            'diff_usd_cnh',
            'diff_usd_kgs',
            'diff_usd_tjs',
        ]
    },
    'bourse-global': {
        title: 'بورس جهانی',
        items: [
            'bourse_nasdaq',
            'bourse_dow',
            'bourse_sp-500',
            'bourse_globaldow',
            'bourse_stoxx-600',
            'bourse_ftse-100',
            'bourse_dax',
            'bourse_cac-40',
            'bourse_asia-dow',
            'bourse_nikkei-225',
            'bourse_hang-seng',
            'bourse_shanghai',
            'bourse_singapore',
            'bourse_ibex-35',
            'bourse_stoxx-600',
            'bourse_ftse-mib',
        ]
    },
    'bourse-iran': {
        title: 'بورس داخلی',
        items: [
            "bourse_22",
            "bourse_61",
            "bourse_57",
            "bourse_58",
            "bourse_56",
            "bourse_47",
            "bourse_52",
            "bourse_5",
            "bourse_35",
            "bourse_50",
            "bourse_60",
            "bourse_59",
            "bourse_21",
            "bourse_38",
            "bourse_34",
            "bourse_8",
            "bourse_13",
            "bourse_53",
            "bourse_44",
            "bourse_17",
            "bourse_42",
            "bourse_26",
            "bourse_32",
            "bourse_11",
            "bourse_14",
            "bourse_28",
            "bourse_2",
            "bourse_33",
            "bourse_36",
            "bourse_39",
            "bourse_31",
            "bourse_45",
            "bourse_23",
            "bourse_1",
            "bourse_3",
            "bourse_51",
            "bourse_15",
            "bourse_16",
            "bourse_40",
            "bourse_9",
            "bourse_10",
            "bourse_46",
            "bourse_48",
            "bourse_12",
            "bourse_20",
            "bourse_29",
            "bourse_4",
            "bourse_7",
            "bourse_25",
            "bourse_43",
            "bourse_19",
            "bourse_41",
            "bourse_49",
            "bourse_18",
        ]
    },
};

export default static_market_page;