const static_market = {
    'local-markets': {
        title: 'بازارهای داخلی',
        static_items: [
            {
                id      : 'mesghal',
                label   : 'بازار طلا',
            },
            {
                id      : 'breakline',
                label   : 'بازار طلا',
            },
            {
                id      : 'geram18',
                label   : 'بازار سکه',
            },
            {
                id      : 'geram24',
                label   : 'بازار ارز',
            },
            {
                id      : 'breakline',
                label   : 'بازار سکه',
            },
            {
                id      : 'sekee',
                label   : 'بازار بورس',
            },
            {
                id      : 'nim',
                label   : 'بازار سهام',
            },
        ],
        items: [
            {
                id      : '28064',
                label   : 'بازار طلا',
                info    : ' آخرین نرخ ها و نوسانات بازار طلا',
                type    : 'folder',
                icon    : 'uil uil-gold',
                img    : 'gold.svg',
                currency : 'ریال',
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'طلا',
                    },
                    {
                        id      : 'geram18',
                        label   : 'طلای 18 عیار / 750',
                    },
                    {
                        id      : 'geram24',
                        label   : 'طلای 24 عیار',
                    },
                    {
                        id      : 'gold_740k',
                        label   : 'طلای 18 عیار / 740',
                    },
                    {
                        id      : 'gold_mini_size',
                        label   : 'طلای دست دوم',
                    },
                    {
                        id      : 'breakline',
                        label   : 'آبشده',
                    },
                    {
                        id      : 'gold_futures',
                        label   : 'آبشده نقدی',
                    },
                    {
                        id      : 'gold_melted_wholesale',
                        label   : 'آبشده بنکداری',
                    },
                    // {
                    //     id      : 'gold_melted_transfer',
                    //     label   : 'آبشده معاملاتی',
                    // },
                    {
                        id      : 'gold_world_futures',
                        label   : 'آبشده کمتر از کیلو',
                    },
                    {
                        id      : 'breakline',
                        label   : 'مظنه / مثقال',
                    },
                    {
                        id      : 'mesghal',
                        label   : 'مثقال طلا',
                    },
                    {
                        id      : 'gold_17',
                        label   : 'مثقال / بدون حباب',
                    },
                    {
                        id      : 'gold_17_transfer',
                        label   : 'حباب آبشده',
                    },
                    {
                        id      : 'gold_17_coin',
                        label   : 'مثقال / بر مبنای سکه',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نقره',
                    },
                    {
                        id      : 'silver_999',
                        label   : 'گرم نقره ۹۹۹',
                    },
                    // {
                    //     id      : 'breakline',
                    //     label   : 'طلا در بورس',
                    // },
                    // {
                    //     id      : '534672',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '534671',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '751030',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '696546',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '751029',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '795197',
                    //     label   : '',
                    // },
                    // {
                    //     id      : '795198',
                    //     label   : '',
                    // },
                ],
            },
            {
                id      : '28066',
                label   : 'بازار سکه',
                info    : ' آخرین نرخ ها و نوسانات بازار سکه',
                type    : 'folder',
                icon    : 'uil uil-coins',
                img    : 'coin.svg',
                currency : 'ریال',
                static_items: [
                    
                    {
                        id      : 'breakline',
                        label   : 'نقدی',
                    },
                    {
                        id      : '137138',
                        label   : 'سکه امامی',
                    },
                    {
                        id      : '137137',
                        label   : 'سکه بهار آزادی',
                    },
                    {
                        id      : '137139',
                        label   : 'نیم سکه',
                    },
                    {
                        id      : '137140',
                        label   : 'ربع سکه',
                    },
                    {
                        id      : '137141',
                        label   : 'سکه گرمی',
                    },
                    {
                        id      : 'breakline',
                        label   : 'تک فروشی',
                    },
                    {
                        id      : '137152',
                        label   : 'سکه امامی',
                    },
                    {
                        id      : '137151',
                        label   : 'سکه بهار آزادی',
                    },
                    {
                        id      : '137153',
                        label   : 'نیم سکه',
                    },
                    {
                        id      : '137154',
                        label   : 'ربع سکه',
                    },
                    {
                        id      : '137155',
                        label   : 'سکه گرمی',
                    },
                    {
                        id      : 'breakline',
                        label   : 'حباب سکه',
                    },
                    {
                        id      : '527832',
                        label   : 'حباب سکه بهار آزادی',
                    },
                    {
                        id      : '524691',
                        label   : 'حباب سکه امامی',
                    },
                    {
                        id      : '527833',
                        label   : 'حباب نیم سکه',
                    },
                    {
                        id      : '527834',
                        label   : 'حباب ربع سکه',
                    },
                    {
                        id      : '527835',
                        label   : 'حباب سکه گرمی',
                    },
                    {
                        id      : 'breakline',
                        label   : 'سایر سکه‌ها',
                    },
                    {
                        id      : '137142',
                        label   : 'تمام سکه (قبل 86)',
                    },
                    {
                        id      : '137143',
                        label   : 'نیم سکه (قبل 86)',
                    },
                    {
                        id      : '137144',
                        label   : 'ربع سکه (قبل 86)',
                    },
                    {
                        id      : 'breakline',
                        label   : 'سکه پارسیان',
                    },
                    {
                        id      : '656112',
                        label   : 'سکه پارسیان ۰/۰۵۰',
                    },
                    {
                        id      : '656113',
                        label   : '',
                    },
                    {
                        id      : '656114',
                        label   : '',
                    },
                    {
                        id      : '656115',
                        label   : '',
                    },
                    {
                        id      : '656116',
                        label   : '',
                    },
                    {
                        id      : '656117',
                        label   : '',
                    },
                    {
                        id      : '656118',
                        label   : '',
                    },
                    {
                        id      : '656119',
                        label   : '',
                    },
                    {
                        id      : '656120',
                        label   : '',
                    },
                    {
                        id      : '656121',
                        label   : '',
                    },
                    {
                        id      : '656122',
                        label   : '',
                    },
                    {
                        id      : '656123',
                        label   : '',
                    },
                    {
                        id      : '656124',
                        label   : '',
                    },
                    {
                        id      : '656125',
                        label   : '',
                    },
                    {
                        id      : '656126',
                        label   : '',
                    },
                    {
                        id      : '656127',
                        label   : '',
                    },
                    {
                        id      : '656128',
                        label   : '',
                    },
                    {
                        id      : '656129',
                        label   : '',
                    },
                    {
                        id      : '656130',
                        label   : '',
                    },
                    {
                        id      : '656131',
                        label   : '',
                    },
                    {
                        id      : '656132',
                        label   : '',
                    },
                    {
                        id      : '656133',
                        label   : '',
                    },
                    {
                        id      : '656134',
                        label   : '',
                    },
                    {
                        id      : '656135',
                        label   : '',
                    },
                    {
                        id      : '656136',
                        label   : '',
                    },
                    // {
                    //     id      : '28055',
                    //     label   : '',
                    // },
                    {
                        id      : 'breakline',
                        label   : 'سکه در بورس',
                    },
                    {
                        id      : '809451',
                        label   : '',
                    },
                    {
                        id      : '768216',
                        label   : '',
                    },
                    {
                        id      : '768217',
                        label   : '',
                    },
                    {
                        id      : '768218',
                        label   : '',
                    },
                    {
                        id      : '696549',
                        label   : '',
                    },
                    {
                        id      : '696550',
                        label   : '',
                    },
                ],
            },
            {
                id      : 'static-dollar',
                label   : 'بازار دلار',
                info    : ' آخرین نرخ ها و نوسانات بازار انواع دلار',
                type    : 'folder',
                icon    : 'uil uil-usd-circle',
                img    : 'dollar.svg',
                currency : 'ریال',
                show_static: true,
                static_items: [
                    {
                        id      : '137204',
                        label   : '',
                    },
                    {
                        id      : '809038',
                        label   : '',
                    },
                    {
                        id      : '137292',
                        label   : '',
                    },
                    {
                        id      : '137308',
                        label   : '',
                    },
                    {
                        id      : '523798',
                        label   : '',
                    },
                    {
                        id      : '523797',
                        label   : '',
                    },
                    {
                        id      : '523875',
                        label   : '',
                    },
                    {
                        id      : '523874',
                        label   : '',
                    },
                    {
                        id      : '137236',
                        label   : '',
                    },
                    {
                        id      : '137241',
                        label   : '',
                    },
                    {
                        id      : '751806',
                        label   : '',
                    },
                    {
                        id      : '137253',
                        label   : '',
                    },
                    {
                        id      : '506998',
                        label   : '',
                    },
                    {
                        id      : '347978',
                        label   : '',
                    },
                ],
            },
            {
                id      : '28069',
                label   : 'بازار ارز',
                info    : ' آخرین نرخ ها و نوسانات بازار ارز',
                type    : 'folder',
                icon    : 'uil uil-usd-circle',
                img    : 'currency.svg',
                currency : 'ریال',
                static_items: [
                    {
                        id      : '137204',
                        label   : '',
                },
                {
                        id      : '137205',
                        label   : '',
                },
                {
                        id      : '137206',
                        label   : '',
                },
                {
                        id      : '137207',
                        label   : '',
                },
                {
                        id      : '137225',
                        label   : '',
                },
                {
                        id      : '137223',
                        label   : '',
                },
                {
                        id      : '137222',
                        label   : '',
                },
                {
                        id      : '137210',
                        label   : '',
                },
                {
                        id      : '520862',
                        label   : '',
                },
                {
                        id      : '137221',
                        label   : '',
                },
                {
                        id      : '137220',
                        label   : '',
                },
                {
                        id      : '137232',
                        label   : '',
                },
                {
                        id      : '137216',
                        label   : '',
                },
                {
                        id      : '137228',
                        label   : '',
                },
                {
                        id      : '137229',
                        label   : '',
                },
                {
                        id      : '137217',
                        label   : '',
                },
                {
                        id      : '137213',
                        label   : '',
                },
                {
                        id      : '137218',
                        label   : '',
                },
                {
                        id      : '137224',
                        label   : '',
                },
                {
                        id      : '137208',
                        label   : '',
                },
                {
                        id      : '137209',
                        label   : '',
                },
                {
                        id      : '137226',
                        label   : '',
                },
                {
                        id      : '137231',
                        label   : '',
                },
                {
                        id      : '137215',
                        label   : '',
                },
                {
                        id      : '137212',
                        label   : '',
                },
                {
                        id      : '137230',
                        label   : '',
                },
                {
                        id      : '137211',
                        label   : '',
                },
                {
                        id      : '137233',
                        label   : '',
                },
                {
                        id      : '137226',
                        label   : '',
                },
                {
                        id      : '137214',
                        label   : '',
                },
                {
                        id      : '137219',
                        label   : '',
                },
                {
                        id      : '137234',
                        label   : '',
                },
                {
                        id      : '137235',
                        label   : '',
                },
                {
                        id      : '520873',
                        label   : '',
                },
                {
                        id      : '520820',
                        label   : '',
                },
                {
                        id      : '520821',
                        label   : '',
                },
                {
                        id      : '520864',
                        label   : '',
                },
                {
                        id      : '520863',
                        label   : '',
                },
                {
                        id      : '520861',
                        label   : '',
                },
                {
                        id      : '520845',
                        label   : '',
                },
                {
                        id      : '520840',
                        label   : '',
                },
                {
                        id      : '520841',
                        label   : '',
                },
                {
                        id      : '520834',
                        label   : '',
                },
                {
                        id      : '520826',
                        label   : '',
                },
                {
                        id      : '520782',
                        label   : '',
                },
                {
                        id      : '520780',
                        label   : '',
                },
                {
                        id      : '520877',
                        label   : '',
                },
                {
                        id      : '520876',
                        label   : '',
                },
                {
                        id      : '520875',
                        label   : '',
                },
                {
                        id      : '520874',
                        label   : '',
                },
                {
                        id      : '520872',
                        label   : '',
                },
                {
                        id      : '520871',
                        label   : '',
                },
                {
                        id      : '520870',
                        label   : '',
                },
                {
                        id      : '520869',
                        label   : '',
                },
                {
                        id      : '520866',
                        label   : '',
                },
                {
                        id      : '520865',
                        label   : '',
                },
                {
                        id      : '520860',
                        label   : '',
                },
                {
                        id      : '520846',
                        label   : '',
                },
                {
                        id      : '520844',
                        label   : '',
                },
                {
                        id      : '520843',
                        label   : '',
                },
                {
                        id      : '520842',
                        label   : '',
                },
                {
                        id      : '520839',
                        label   : '',
                },
                {
                        id      : '520838',
                        label   : '',
                },
                {
                        id      : '520837',
                        label   : '',
                },
                {
                        id      : '520836',
                        label   : '',
                },
                {
                        id      : '520835',
                        label   : '',
                },
                {
                        id      : '520833',
                        label   : '',
                },
                {
                        id      : '520832',
                        label   : '',
                },
                {
                        id      : '520831',
                        label   : '',
                },
                {
                        id      : '520830',
                        label   : '',
                },
                {
                        id      : '520829',
                        label   : '',
                },
                {
                        id      : '520828',
                        label   : '',
                },
                {
                        id      : '520827',
                        label   : '',
                },
                {
                        id      : '520825',
                        label   : '',
                },
                {
                        id      : '520824',
                        label   : '',
                },
                {
                        id      : '520823',
                        label   : '',
                },
                {
                        id      : '520822',
                        label   : '',
                },
                {
                        id      : '520819',
                        label   : '',
                },
                {
                        id      : '520818',
                        label   : '',
                },
                {
                        id      : '520817',
                        label   : '',
                },
                {
                        id      : '520816',
                        label   : '',
                },
                {
                        id      : '520815',
                        label   : '',
                },
                {
                        id      : '520814',
                        label   : '',
                },
                {
                        id      : '520813',
                        label   : '',
                },
                {
                        id      : '520812',
                        label   : '',
                },
                {
                        id      : '520811',
                        label   : '',
                },
                {
                        id      : '520810',
                        label   : '',
                },
                {
                        id      : '520809',
                        label   : '',
                },
                {
                        id      : '520808',
                        label   : '',
                },
                {
                        id      : '520807',
                        label   : '',
                },
                {
                        id      : '520805',
                        label   : '',
                },
                {
                        id      : '520804',
                        label   : '',
                },
                {
                        id      : '520803',
                        label   : '',
                },
                {
                        id      : '520802',
                        label   : '',
                },
                {
                        id      : '520801',
                        label   : '',
                },
                {
                        id      : '520800',
                        label   : '',
                },
                {
                        id      : '520799',
                        label   : '',
                },
                {
                        id      : '520798',
                        label   : '',
                },
                {
                        id      : '520797',
                        label   : '',
                },
                {
                        id      : '520796',
                        label   : '',
                },
                {
                        id      : '520795',
                        label   : '',
                },
                {
                        id      : '520794',
                        label   : '',
                },
                {
                        id      : '520793',
                        label   : '',
                },
                {
                        id      : '520792',
                        label   : '',
                },
                {
                        id      : '520791',
                        label   : '',
                },
                {
                        id      : '520790',
                        label   : '',
                },
                {
                        id      : '520783',
                        label   : '',
                },
                {
                        id      : '520781',
                        label   : '',
                },
                {
                        id      : '520779',
                        label   : '',
                },
                {
                        id      : '520778',
                        label   : '',
                },
                {
                        id      : '520777',
                        label   : '',
                },
                {
                        id      : '520776',
                        label   : '',
                },
                {
                        id      : '520775',
                        label   : '',
                },
                {
                        id      : '520774',
                        label   : '',
                },
                {
                        id      : '520773',
                        label   : '',
                },
                {
                        id      : '520772',
                        label   : '',
                },
                {
                        id      : '520771',
                        label   : '',
                },
                {
                        id      : '520770',
                        label   : '',
                },
                {
                        id      : '520768',
                        label   : '',
                },
                {
                        id      : '520766',
                        label   : '',
                },
                {
                        id      : '520737',
                        label   : '',
                },
                {
                        id      : '520736',
                        label   : '',
                },
                {
                        id      : '520735',
                        label   : '',
                },
                {
                        id      : '520734',
                        label   : '',
                },
                {
                        id      : '520733',
                        label   : '',
                },
                {
                        id      : '520732',
                        label   : '',
                },
                {
                        id      : '520731',
                        label   : '',
                },
                {
                        id      : '520729',
                        label   : '',
                },
                {
                        id      : '520728',
                        label   : '',
                },
                {
                        id      : '520727',
                        label   : '',
                },
                {
                        id      : '520726',
                        label   : '',
                },
                {
                        id      : '520725',
                        label   : '',
                },
                {
                        id      : '520724',
                        label   : '',
                },
                {
                        id      : '520723',
                        label   : '',
                },
                {
                        id      : '520722',
                        label   : '',
                },
                {
                        id      : '520721',
                        label   : '',
                },
                {
                        id      : '520720',
                        label   : '',
                },
                {
                        id      : '520719',
                        label   : '',
                },
                {
                        id      : '520718',
                        label   : '',
                },
                {
                        id      : '520717',
                        label   : '',
                },
                {
                        id      : '520716',
                        label   : '',
                },
                {
                        id      : '520715',
                        label   : '',
                },
                {
                        id      : '520714',
                        label   : '',
                },
                ],
            },
            // {
            //     id      : 'stocks-bourse',
            //     label   : 'بازار بورس',
            //     info    : 'قیمت خرید، فروش و تغییرات در بازار بورس جهانی و ایران',
            //     type    : 'folder',
            //     icon    : 'uil uil-presentation-line',
            //     img    : 'money-exchange.svg',
            // },
            {
                id      : 'stocks-index',
                label   : 'شاخص ها',
                info    : ' آخرین نرخ ها و نوسانات بازار بورس',
                type    : 'folder',
                icon    : 'uil uil-presentation-line',
                img    : 'money-exchange.svg',
            },
            {
                id      : 'stocks-stock',
                label   : 'بازار سهام',
                info    : ' آخرین نرخ ها و نوسانات بازار سهام',
                type    : 'folder',
                icon    : 'uil uil-file-graph',
                img    : 'stock-market.svg',
            },
            {
                id      : 'stocks-bond',
                label   : 'بازار اوراق',
                info    : ' آخرین نرخ ها و نوسانات بازار اوراق',
                type    : 'folder',
                icon    : 'uil uil-layer-group',
                img    : 'bond.svg',
            },
            {
                id      : 'stocks-future',
                label   : 'بازار آتی',
                info    : ' آخرین نرخ ها و نوسانات بازار آتی',
                type    : 'folder',
                icon    : 'uil uil-files-landscapes-alt',
                img    : 'dividend.svg',
            },
            {
                id      : 'stocks-option',
                label   : 'بازار آپشن',
                info    : ' آخرین نرخ ها و نوسانات بازار آپشن',
                type    : 'folder',
                icon    : 'uil uil-file-landscape-alt',
                img    : 'stock.svg',
            },
            {
                id      : 'stocks-fund',
                label   : 'صندوق‌ها',
                info    : ' آخرین نرخ ها و نوسانات بازار صندوق‌ها',
                type    : 'folder',
                icon    : 'uil uil-boombox',
                img    : 'loan.svg',
            },
        ]
    },
    'global-markets': {
        title: 'بازارهای جهانی',
        static_items: [
            {
                id      : '131398',
                label   : 'نفت برنت',
            },{
                id      : '137118',
                label   : 'انس طلا',
            },
            {
                id      : '239613',
                label   : 'روی',
            },
            {
                id      : '239616',
                label   : 'مس',
            },
            {
                id      : '239615',
                label   : 'نیکل',
            },
            {
                id      : '131419',
                label   : 'آلومینیوم',
            },
        ],
        items: [
            {
                id      : '28048',
                label   : 'بازار فلزات گرانبها',
                info    : ' آخرین نرخ ها و نوسانات بازار جهانی فلزات گرانبها',
                type    : 'folder',
                icon    : 'uil uil-gold',
                img    : 'gold(1).svg',
                show_static: true,
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'انس‌های جهانی',
                    },
                    {
                        id      : '137119',
                        label   : 'انس طلا',
                    },
                    {
                        id      : '137123',
                        label   : 'انس نقره',
                    },
                    {
                        id      : '137132',
                        label   : 'انس پلاتین',
                    },
                    {
                        id      : '137133',
                        label   : 'انس پالادیوم',
                    },
                    {
                        id      : 'breakline',
                        label   : 'بازار فلزات آمریکا',
                    },
                    {
                        id      : '131433',
                        label   : '',
                    },
                    {
                        id      : '131432',
                        label   : '',
                    },
                    {
                        id      : '131429',
                        label   : '',
                    },
                    {
                        id      : '131434',
                        label   : '',
                    },
                    {
                        id      : '131440',
                        label   : '',
                    },
                    {
                        id      : '131430',
                        label   : '',
                    },
                    {
                        id      : '131435',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'طلا برابر سایر شاخص‌ها',
                    },
                    {
                        id      : '137124',
                        label   : 'برابری طلا / نقره',
                    },
                    {
                        id      : '137125',
                        label   : 'برابری طلا / پلاتین',
                    },
                    {
                        id      : '137126',
                        label   : 'برابری طلا / پالادیوم',
                    },
                    {
                        id      : '137131',
                        label   : 'برابری طلا / نفت خام',
                    },
                    {
                        id      : '137130',
                        label   : 'برابری طلا / داوجونز',
                    },
                    {
                        id      : '137129',
                        label   : 'برابری طلا / S&P 500 ',
                    },
                    {
                        id      : '137128',
                        label   : 'برابری طلا / شاخص HUI',
                    },
                    {
                        id      : '137127',
                        label   : 'برابری طلا / شاخص XAU',
                    },
                    // {
                    //     id      : '131429',
                    //     label   : 'طلا (بازار آمریکا)',
                    // },
                    // {
                    //     id      : '131430',
                    //     label   : 'نقره (بازار آمریکا)',
                    // },
                ],
            },
            {
                id      : '28047',
                label   : 'بازار فلزات پایه',
                info    : ' آخرین نرخ ها و نوسانات بازار جهانی فلزات پایه',
                type    : 'folder',
                icon    : 'uil uil-vertical-align-center',
                img    : 'gold(2).svg',
                show_static: true,
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'بازار جهانی',
                    },
                    {
                        id      : 'aluminium',
                        label   : 'آلومینیوم',
                    },
                    {
                        id      : 'base_global_tin',
                        label   : 'قلع',
                    },
                    {
                        id      : 'base_global_copper',
                        label   : 'مس',
                    },
                    {
                        id      : 'base_global_zinc',
                        label   : 'روی',
                    },
                    {
                        id      : 'base_global_lead',
                        label   : 'سرب',
                    },
                    {
                        id      : 'base_global_nickel',
                        label   : 'نیکل',
                    },
                    {
                        id      : 'cobalt',
                        label   : 'کبالت',
                    },
                    {
                        id      : 'base_global_copper2',
                        label   : '	مس',
                    },
                    {
                        id      : 'breakline',
                        label   : 'بازار آمریکا',
                    },
                    {
                        id      : 'base-us-aluminum',
                        label   : 'آلومینیوم',
                    },
                    {
                        id      : 'base-us-uranium',
                        label   : 'اورانیوم',
                    },
                    {
                        id      : 'base-us-zinc',
                        label   : 'روی ( CME )',
                    },
                    {
                        id      : 'base-us-copper',
                        label   : 'مس (های گرید)',
                    },
                    {
                        id      : 'base-us-steel-coil',
                        label   : 'فولاد',
                    },
                    {
                        id      : 'base-us-iron-ore',
                        label   : 'سنگ آهن',
                    },
                ],
            },
            {
                id      : '28049',
                label   : 'بازار نفت و انرژی',
                info    : ' آخرین نرخ ها و نوسانات بازار جهانی نفت و انرژی',
                type    : 'folder',
                icon    : 'uil uil-bolt',
                img    : 'oil-market.svg',
                show_static: true,
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'نمای کلی',
                    },
                    {
                        id      : '131400',
                        label   : 'نفت سبک',
                    },
                    {
                        id      : '131401',
                        label   : 'بنزین (RBOB)',
                    },
                    {
                        id      : '131402',
                        label   : 'نفت کوره',
                    },
                    {
                        id      : '131404',
                        label   : 'گاز طبیعی',
                    },
                    {
                        id      : '131398',
                        label   : 'نفت برنت',
                    },
                    {
                        id      : '131403',
                        label   : 'گازوییل',
                    },
                    {
                        id      : '131399',
                        label   : 'ذغال سنگ',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت اوپک',
                    },
                    {
                        id      : '657829',
                        label   : 'نفت موربان امارات',
                    },
                    {
                        id      : '658101',
                        label   : 'نفت سنگین ایران',
                    },
                    {
                        id      : '657830',
                        label   : 'نفت سبک بصره',
                    },
                    {
                        id      : '657831',
                        label   : 'نفت ساحاران الجزایر',
                    },
                    {
                        id      : '657832',
                        label   : 'نفت سبک نیجریه',
                    },
                    {
                        id      : '657833',
                        label   : 'نفت گیراسول آنگولا',
                    },
                    {
                        id      : '657834',
                        label   : 'نفت سبک عربستان',
                    },
                    {
                        id      : '657836',
                        label   : 'نفت کویت',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت ایران . تحویل در اروپای غربی',
                    },
                    {
                        id      : '658097',
                        label   : '',
                    },
                    {
                        id      : '658098',
                        label   : '',
                    },
                    {
                        id      : '658099',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت ایران . تحویل در جنوب آفریقا',
                    },
                    {
                        id      : '658111',
                        label   : '',
                    },
                    {
                        id      : '658112',
                        label   : '',
                    },
                    {
                        id      : '658113',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت ایران . تحویل در شمال آفریقا',
                    },
                    {
                        id      : '658108',
                        label   : '',
                    },
                    {
                        id      : '658109',
                        label   : '',
                    },
                    {
                        id      : '658110',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت ایران . تحویل در مدیترانه',
                    },
                    {
                        id      : '658103',
                        label   : '',
                    },
                    {
                        id      : '658104',
                        label   : '',
                    },
                    {
                        id      : '658106',
                        label   : '',
                    },
                    {
                        id      : '658105',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت بصره',
                    },
                    {
                        id      : '657841',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت عربستان',
                    },
                    {
                        id      : '657843',
                        label   : '',
                    },
                    {
                        id      : '753654',
                        label   : '',
                    },
                    {
                        id      : '657842',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت امارات',
                    },
                    {
                        id      : '657818',
                        label   : '',
                    },
                    {
                        id      : '657837',
                        label   : '',
                    },
                    {
                        id      : '753650',
                        label   : '',
                    },
                    {
                        id      : '753651',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت قطر',
                    },
                    {
                        id      : '657839',
                        label   : '',
                    },
                    {
                        id      : '657838',
                        label   : '',
                    },
                    {
                        id      : '657840',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت مکزیک',
                    },
                    {
                        id      : '657815',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت مکزیک . تحویل در شرق دور',
                    },
                    {
                        id      : '658086',
                        label   : '',
                    },
                    {
                        id      : '658087',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت مکزیک . تحویل در خلیج فارس',
                    },
                    {
                        id      : '657938',
                        label   : '',
                    },
                    {
                        id      : '657939',
                        label   : '',
                    },
                    {
                        id      : '657940',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت مکزیک . تحویل در  سواحل غرب آمریکا',
                    },
                    {
                        id      : '658088',
                        label   : '',
                    },
                    {
                        id      : '658089',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا',
                    },
                    {
                        id      : '131416',
                        label   : '',
                    },
                    {
                        id      : '131412',
                        label   : '',
                    },
                    {
                        id      : '131411',
                        label   : '',
                    },
                    {
                        id      : '131406',
                        label   : '',
                    },
                    {
                        id      : '131410',
                        label   : '',
                    },
                    {
                        id      : '131413',
                        label   : '',
                    },
                    {
                        id      : '131414',
                        label   : '',
                    },
                    {
                        id      : '131415',
                        label   : '',
                    },
                    {
                        id      : '131418',
                        label   : '',
                    },
                    {
                        id      : '657823',
                        label   : '',
                    },
                    {
                        id      : '657824',
                        label   : '',
                    },
                    {
                        id      : '657821',
                        label   : '',
                    },
                    {
                        id      : '657825',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . اوکلاهما',
                    },
                    {
                        id      : '657908',
                        label   : '',
                    },
                    {
                        id      : '657906',
                        label   : '',
                    },
                    {
                        id      : '657905',
                        label   : '',
                    },
                    {
                        id      : '657907',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . آرکانزاس',
                    },
                    {
                        id      : '657886',
                        label   : '',
                    },
                    {
                        id      : '657885',
                        label   : '',
                    },
                    {
                        id      : '657884',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . تگزاس',
                    },
                    {
                        id      : '657870',
                        label   : '',
                    },
                    {
                        id      : '657880',
                        label   : '',
                    },
                    {
                        id      : '657879',
                        label   : '',
                    },
                    {
                        id      : '657881',
                        label   : '',
                    },
                    {
                        id      : '657882',
                        label   : '',
                    },
                    {
                        id      : '657883',
                        label   : '',
                    },
                    {
                        id      : '657874',
                        label   : '',
                    },
                    {
                        id      : '657869',
                        label   : '',
                    },
                    {
                        id      : '657873',
                        label   : '',
                    },
                    {
                        id      : '657877',
                        label   : '',
                    },
                    {
                        id      : '657875',
                        label   : '',
                    },
                    {
                        id      : '657876',
                        label   : '',
                    },
                    {
                        id      : '657872',
                        label   : '',
                    },
                    {
                        id      : '657871',
                        label   : '',
                    },
                    {
                        id      : '657878',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . کالیفرنیا',
                    },
                    {
                        id      : '657887',
                        label   : '',
                    },
                    {
                        id      : '657888',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . کانزاس',
                    },
                    {
                        id      : '657898',
                        label   : '',
                    },
                    {
                        id      : '657896',
                        label   : '',
                    },
                    {
                        id      : '657897',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . جنوب شرقی کلرادو',
                    },
                    {
                        id      : '657894',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . لوئیزیانا',
                    },
                    {
                        id      : '657820',
                        label   : '',
                    },
                    {
                        id      : '657900',
                        label   : '',
                    },
                    {
                        id      : '657901',
                        label   : '',
                    },
                    {
                        id      : '657899',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . میشیگان',
                    },
                    {
                        id      : '657902',
                        label   : '',
                    },
                    {
                        id      : '657903',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . نبراسکا',
                    },
                    {
                        id      : '657904',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . وایومینگ',
                    },
                    {
                        id      : '657909',
                        label   : '',
                    },
                    {
                        id      : '657910',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آمریکا . یوتا',
                    },
                    {
                        id      : '657891',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت کانادا',
                    },
                    {
                        id      : '657817',
                        label   : '',
                    },
                    {
                        id      : '657826',
                        label   : '',
                    },
                    {
                        id      : '657865',
                        label   : '',
                    },
                    {
                        id      : '657864',
                        label   : '',
                    },
                    {
                        id      : '657862',
                        label   : '',
                    },
                    {
                        id      : '657863',
                        label   : '',
                    },
                    {
                        id      : '657867',
                        label   : '',
                    },
                    {
                        id      : '657861',
                        label   : '',
                    },
                    {
                        id      : '657866',
                        label   : '',
                    },
                    {
                        id      : '657868',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت چین',
                    },
                    {
                        id      : '657852',
                        label   : '',
                    },
                    {
                        id      : '657853',
                        label   : '',
                    },
                    {
                        id      : '657854',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آنگولا',
                    },
                    {
                        id      : '657848',
                        label   : '',
                    },
                    {
                        id      : '657847',
                        label   : '',
                    },
                    {
                        id      : '657846',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت اندونزی',
                    },
                    {
                        id      : '657855',
                        label   : '',
                    },
                    {
                        id      : '657856',
                        label   : '',
                    },
                    {
                        id      : '657857',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت استرالیا',
                    },
                    {
                        id      : '657849',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت روسیه',
                    },
                    {
                        id      : '657859',
                        label   : '',
                    },
                    {
                        id      : '657858',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت مالزی',
                    },
                    {
                        id      : '657860',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت نیجریه',
                    },
                    {
                        id      : '657845',
                        label   : '',
                    },
                    {
                        id      : '657844',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت ژاپن',
                    },
                    {
                        id      : '657828',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت هند',
                    },
                    {
                        id      : '657816',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت عمان',
                    },
                    {
                        id      : '753657',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت هلند',
                    },
                    {
                        id      : '657827',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت برزیل',
                    },
                    {
                        id      : '753655',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'نفت آذربایجان',
                    },
                    {
                        id      : '753656',
                        label   : '',
                    },
                ],
            },
            {
                id      : '28042',
                label   : 'بازارهای کالایی',
                info    : ' آخرین نرخ ها و نوسانات بازار جهانی کالایی',
                type    : 'folder',
                icon    : 'uil uil-shopping-basket',
                img    : 'commodity(2).svg',
                show_static: true,
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'محصولات کشاورزی',
                    },
                    {
                        id      : 'commodity_london_wheat',
                        label   : 'گندم',
                    },
                    {
                        id      : 'commodity_us_wheat',
                        label   : 'گندم',
                    },
                    {
                        id      : 'commodity_rough_rice',
                        label   : 'برنج',
                    },
                    {
                        id      : 'commodity_us_corn',
                        label   : 'ذرت',
                    },
                    {
                        id      : 'commodity_oats',
                        label   : 'جو',
                    },
                    {
                        id      : 'commodity_us_soybeans',
                        label   : 'سویا',
                    },
                    {
                        id      : 'commodity_us_soybean_oil',
                        label   : 'روغن سویا',
                    },
                    {
                        id      : 'commodity_us_soybean_meal',
                        label   : 'کنجاله سویا',
                    },
                    {
                        id      : 'breakline',
                        label   : 'محصولات زراعی',
                    },
                    {
                        id      : 'commodity_london_sugar',
                        label   : 'شکر',
                    },
                    {
                        id      : 'commodity_london_coffee',
                        label   : 'قهوه',
                    },
                    {
                        id      : 'commodity_london_cocoa',
                        label   : 'کاکائو',
                    },
                    {
                        id      : 'commodity_us_sugar_no11',
                        label   : 'شکر',
                    },
                    {
                        id      : 'commodity_us_cotton_no_2',
                        label   : 'پنبه',
                    },
                    {
                        id      : 'commodity_us_coffee_c',
                        label   : 'قهوه',
                    },
                    {
                        id      : 'commodity_us_cocoa',
                        label   : 'کاکائو',
                    },
                    {
                        id      : 'commodity_lumber',
                        label   : 'الوار',
                    },
                    {
                        id      : 'commodity_orange_juice',
                        label   : 'آب پرتقال',
                    },
                    {
                        id      : 'breakline',
                        label   : 'محصولات دامی',
                    },
                    {
                        id      : 'commodity_live_cattle',
                        label   : 'گوشت گاو (1 کیلو)',
                    },
                    {
                        id      : 'commodity_feed_cattle',
                        label   : 'فیدر گاو',
                    },
                ],
            },
            {
                id      : '29906',
                label   : 'بازار ارزهای دیجیتال',
                info    : ' آخرین نرخ ها و نوسانات  بازارهای جهانی ارز دیجیتال',
                type    : 'folder',
                icon    : 'uil uil-bitcoin-circle',
                img    : 'cryptocurrency.svg',
                show_static: false,
                static_items: [
                    {
                        id      : '398096',
                        label   : 'بیت کوین',
                    },
                    {
                        id      : '398097',
                        label   : 'اتریوم',
                    },
                    {
                        id      : '398115',
                        label   : 'تتر',
                    },
                    {
                        id      : '398110',
                        label   : 'لایت کوین',
                    },
                    {
                        id      : '522780',
                        label   : 'بیت کوین کش',
                    },
                    {
                        id      : '398098',
                        label   : '',
                    },
                    {
                        id      : '535884',
                        label   : '',
                    },
                    {
                        id      : '398102',
                        label   : '',
                    },
                    {
                        id      : '535605',
                        label   : '',
                    },
                    {
                        id      : '743864',
                        label   : '',
                    },
                    {
                        id      : '398130',
                        label   : '',
                    },
                    {
                        id      : '531365',
                        label   : '',
                    },
                    {
                        id      : '398693',
                        label   : '',
                    },
                    {
                        id      : '743921',
                        label   : '',
                    },
                    {
                        id      : '398413',
                        label   : '',
                    },
                    {
                        id      : '765345',
                        label   : '',
                    },
                    {
                        id      : '398109',
                        label   : '',
                    },
                    {
                        id      : '753968',
                        label   : '',
                    },
                    {
                        id      : '677613',
                        label   : '',
                    },
                    {
                        id      : '536880',
                        label   : '',
                    },
                    {
                        id      : '671656',
                        label   : '',
                    },
                    {
                        id      : '398919',
                        label   : '',
                    },
                    {
                        id      : '667250',
                        label   : '',
                    },
                    {
                        id      : '535026',
                        label   : '',
                    },
                    {
                        id      : '778706',
                        label   : '',
                    },
                    {
                        id      : '739130',
                        label   : '',
                    },
                    {
                        id      : '743870',
                        label   : '',
                    },
                    {
                        id      : '398101',
                        label   : '',
                    },
                    {
                        id      : '399180',
                        label   : '',
                    },
                    {
                        id      : '398194',
                        label   : '',
                    },
                    {
                        id      : '398112',
                        label   : '',
                    },
                    {
                        id      : '534925',
                        label   : '',
                    },
                    {
                        id      : '398103',
                        label   : '',
                    },
                    {
                        id      : '764522',
                        label   : '',
                    },
                    {
                        id      : '744777',
                        label   : '',
                    },
                    {
                        id      : '398106',
                        label   : '',
                    },
                    {
                        id      : '670207',
                        label   : '',
                    },
                    {
                        id      : '524707',
                        label   : '',
                    },
                    {
                        id      : '731725',
                        label   : '',
                    },
                    {
                        id      : '533622',
                        label   : '',
                    },
                    {
                        id      : '398099',
                        label   : '',
                    },
                    {
                        id      : '535906',
                        label   : '',
                    },
                    {
                        id      : '398111',
                        label   : '',
                    },
                    {
                        id      : '783774',
                        label   : '',
                    },
                    {
                        id      : '671437',
                        label   : '',
                    },
                    {
                        id      : '399585',
                        label   : '',
                    },
                    {
                        id      : '764637',
                        label   : '',
                    },
                    {
                        id      : '743868',
                        label   : '',
                    },
                    {
                        id      : '742547',
                        label   : '',
                    },
                    {
                        id      : '766542',
                        label   : '',
                    },
                    {
                        id      : '671778',
                        label   : '',
                    },
                    {
                        id      : '661938',
                        label   : '',
                    },
                    {
                        id      : '678702',
                        label   : '',
                    },
                    {
                        id      : '399491',
                        label   : '',
                    },
                    {
                        id      : '398217',
                        label   : '',
                    },
                    {
                        id      : '671687',
                        label   : '',
                    },
                    {
                        id      : '399711',
                        label   : '',
                    },
                    {
                        id      : '741277',
                        label   : '',
                    },
                    {
                        id      : '398264',
                        label   : '',
                    },
                    {
                        id      : '534750',
                        label   : '',
                    },
                    {
                        id      : '398100',
                        label   : '',
                    },
                    {
                        id      : '398133',
                        label   : '',
                    },
                    {
                        id      : '534927',
                        label   : '',
                    },
                    {
                        id      : '755500',
                        label   : '',
                    },
                    {
                        id      : '743866',
                        label   : '',
                    },
                    {
                        id      : '743874',
                        label   : '',
                    },
                    {
                        id      : '734022',
                        label   : '523210',
                    },
                    {
                        id      : '671671',
                        label   : '',
                    },
                    {
                        id      : '739888',
                        label   : '',
                    },
                    {
                        id      : '743880',
                        label   : '',
                    },
                    {
                        id      : '783728',
                        label   : '',
                    },
                    {
                        id      : '398119',
                        label   : '',
                    },
                    {
                        id      : '398122',
                        label   : '',
                    },
                    {
                        id      : '741281',
                        label   : '',
                    },
                    {
                        id      : '743923',
                        label   : '',
                    },
                    {
                        id      : '794940',
                        label   : '',
                    },
                    {
                        id      : '779687',
                        label   : '',
                    },
                    {
                        id      : '761194',
                        label   : '',
                    },
                    {
                        id      : '757350',
                        label   : '',
                    },
                    {
                        id      : '700610',
                        label   : '',
                    },
                    {
                        id      : '535949',
                        label   : '',
                    },
                    {
                        id      : '398104',
                        label   : '',
                    },
                    {
                        id      : '706265',
                        label   : '',
                    },
                    {
                        id      : '625667',
                        label   : '',
                    },
                    {
                        id      : '670091',
                        label   : '',
                    },
                    {
                        id      : '667248',
                        label   : '',
                    },
                    {
                        id      : '657257',
                        label   : '',
                    },
                    {
                        id      : '398105',
                        label   : '',
                    },
                    {
                        id      : '534920',
                        label   : '',
                    },
                    {
                        id      : '534951',
                        label   : '',
                    },
                    {
                        id      : '535823',
                        label   : '',
                    },
                    {
                        id      : '766023',
                        label   : '',
                    },
                    {
                        id      : '535209',
                        label   : '',
                    },
                    {
                        id      : '465485',
                        label   : '',
                    },
                    {
                        id      : '743916',
                        label   : '',
                    },
                    {
                        id      : '743900',
                        label   : '',
                    },
                    {
                        id      : '669768',
                        label   : '',
                    },
                    {
                        id      : '744008',
                        label   : '',
                    },
                    {
                        id      : '778678',
                        label   : '',
                    },
                    {
                        id      : '398107',
                        label   : '',
                    },
                    {
                        id      : '671703',
                        label   : '',
                    },
                    {
                        id      : '731724',
                        label   : '',
                    },
                    {
                        id      : '398236',
                        label   : '',
                    },
                    {
                        id      : '743876',
                        label   : '',
                    },
                    {
                        id      : '398134',
                        label   : '',
                    },
                    {
                        id      : '743911',
                        label   : '',
                    },
                    {
                        id      : '731726',
                        label   : '',
                    },
                    {
                        id      : '783742',
                        label   : '',
                    },
                    {
                        id      : '739424',
                        label   : '',
                    },
                    {
                        id      : '743877',
                        label   : '',
                    },
                    {
                        id      : '536297',
                        label   : '',
                    },
                    {
                        id      : '535052',
                        label   : '',
                    },
                    {
                        id      : '398142',
                        label   : '',
                    },
                    {
                        id      : '743882',
                        label   : '',
                    },
                    {
                        id      : '761738',
                        label   : '',
                    },
                    {
                        id      : '685489',
                        label   : '',
                    },
                    {
                        id      : '398136',
                        label   : '',
                    },
                    {
                        id      : '741232',
                        label   : '',
                    },
                    {
                        id      : '783777',
                        label   : '',
                    },
                    {
                        id      : '398368',
                        label   : '',
                    },
                    {
                        id      : '671689',
                        label   : '',
                    },
                    {
                        id      : '669767',
                        label   : '',
                    },
                    {
                        id      : '398108',
                        label   : '',
                    },
                    {
                        id      : '743896',
                        label   : '',
                    },
                    {
                        id      : '766471',
                        label   : '',
                    },
                    {
                        id      : '398143',
                        label   : '',
                    },
                    {
                        id      : '743872',
                        label   : '',
                    },
                    {
                        id      : '704201',
                        label   : '',
                    },
                    {
                        id      : '671648',
                        label   : '',
                    },
                    {
                        id      : '399350',
                        label   : '',
                    },
                    {
                        id      : '678810',
                        label   : '',
                    },
                    {
                        id      : '743929',
                        label   : '',
                    },
                    {
                        id      : '743885',
                        label   : '',
                    },
                    {
                        id      : '536757',
                        label   : '',
                    },
                    {
                        id      : '398118',
                        label   : '',
                    },
                    {
                        id      : '736170',
                        label   : '',
                    },
                    {
                        id      : '741299',
                        label   : '',
                    },
                    {
                        id      : '734980',
                        label   : '',
                    },
                    {
                        id      : '671719',
                        label   : '',
                    },
                    {
                        id      : '398215',
                        label   : '',
                    },
                    {
                        id      : '663130',
                        label   : '',
                    },
                    {
                        id      : '743928',
                        label   : '',
                    },
                    {
                        id      : '534982',
                        label   : '',
                    },
                    {
                        id      : '398113',
                        label   : '',
                    },
                    {
                        id      : '398150',
                        label   : '',
                    },
                    {
                        id      : '671786',
                        label   : '',
                    },
                    {
                        id      : '533033',
                        label   : '',
                    },
                    {
                        id      : '743902',
                        label   : '',
                    },
                    {
                        id      : '505014',
                        label   : '',
                    },
                    {
                        id      : '743906',
                        label   : '',
                    },
                    {
                        id      : '671762',
                        label   : '',
                    },
                    {
                        id      : '708316',
                        label   : '',
                    },
                    {
                        id      : '658309',
                        label   : '',
                    },
                    {
                        id      : '743926',
                        label   : '',
                    },
                    {
                        id      : '534918',
                        label   : '',
                    },
                    {
                        id      : '743949',
                        label   : '',
                    },
                    {
                        id      : '671650',
                        label   : '',
                    },
                    {
                        id      : '762074',
                        label   : '',
                    },
                    {
                        id      : '535034',
                        label   : '',
                    },
                    {
                        id      : '532726',
                        label   : '',
                    },
                    {
                        id      : '746374',
                        label   : '',
                    },
                    {
                        id      : '743884',
                        label   : '',
                    },
                    {
                        id      : '398114',
                        label   : '',
                    },
                    {
                        id      : '741898',
                        label   : '',
                    },
                    {
                        id      : '535814',
                        label   : '',
                    },
                    {
                        id      : '536450',
                        label   : '',
                    },
                    {
                        id      : '398139',
                        label   : '',
                    },
                    {
                        id      : '656410',
                        label   : '',
                    },
                    {
                        id      : '743890',
                        label   : '',
                    },
                    {
                        id      : '671955',
                        label   : '',
                    },
                    {
                        id      : '398379',
                        label   : '',
                    },
                    {
                        id      : '765351',
                        label   : '398116',
                    },
                    {
                        id      : '743912',
                        label   : '',
                    },
                    {
                        id      : '676972',
                        label   : '',
                    },
                    {
                        id      : '743888',
                        label   : '',
                    },
                    {
                        id      : '757331',
                        label   : '',
                    },
                    {
                        id      : '661669',
                        label   : '',
                    },
                    {
                        id      : '398491',
                        label   : '',
                    },
                    {
                        id      : '398200',
                        label   : '',
                    },
                    {
                        id      : '398140',
                        label   : '',
                    },
                    {
                        id      : '534656',
                        label   : '',
                    },
                    {
                        id      : '398131',
                        label   : '',
                    },
                    {
                        id      : '675713',
                        label   : '',
                    },
                    {
                        id      : '733088',
                        label   : '',
                    },
                    {
                        id      : '743879',
                        label   : '',
                    },
                    {
                        id      : '784639',
                        label   : '',
                    },
                    {
                        id      : '783945',
                        label   : '',
                    },
                    {
                        id      : '675465',
                        label   : '',
                    },
                    {
                        id      : '757270',
                        label   : '',
                    },
                    {
                        id      : '398205',
                        label   : '',
                    },
                    {
                        id      : '750711',
                        label   : '',
                    },
                    {
                        id      : '743940',
                        label   : '',
                    },
                    {
                        id      : '784589',
                        label   : '',
                    },
                    {
                        id      : '535180',
                        label   : '',
                    },
                    {
                        id      : '744000',
                        label   : '',
                    }
                ],
            },
            {
                id      : 'currencies',
                label   : 'بازارهای ارزی',
                info    : 'آخرین نرخ ها و نوسانات بازارهای ارزی کشورها',
                type    : 'folder',
                icon    : 'uil uil-usd-circle',
                img    : 'currency(1).svg',
                static_items: [
                {
                        id      : '136737',
                        label   : '',
                },
                {
                        id      : '136611',
                        label   : '',
                },
                {
                        id      : '136741',
                        label   : '',
                },
                {
                        id      : '136613',
                        label   : '',
                },
                {
                        id      : '136615',
                        label   : '',
                },
                {
                        id      : '136679',
                        label   : '',
                },
                {
                        id      : '136617',
                        label   : '',
                },
                {
                        id      : '136673',
                        label   : '',
                },
                {
                        id      : '136693',
                        label   : '',
                },
                {
                        id      : '136619',
                        label   : '',
                },
                {
                        id      : '136621',
                        label   : '',
                },
                {
                        id      : '136671',
                        label   : '',
                },
                {
                        id      : '136817',
                        label   : '',
                },
                {
                        id      : '136623',
                        label   : '',
                },
                {
                        id      : '136627',
                        label   : '',
                },
                {
                        id      : '136915',
                        label   : '',
                },
                {
                        id      : '136635',
                        label   : '',
                },
                {
                        id      : '136913',
                        label   : '',
                },
                {
                        id      : '136641',
                        label   : '',
                },
                {
                        id      : '136905',
                        label   : '',
                },
                {
                        id      : '136639',
                        label   : '',
                },
                {
                        id      : '136897',
                        label   : '',
                },
                {
                        id      : '136655',
                        label   : '',
                },
                {
                        id      : '136891',
                        label   : '',
                },
                {
                        id      : '136663',
                        label   : '',
                },
                {
                        id      : '136911',
                        label   : '',
                },
                {
                        id      : '136659',
                        label   : '',
                },
                {
                        id      : '136953',
                        label   : '',
                },
                {
                        id      : '136733',
                        label   : '',
                },
                {
                        id      : '136939',
                        label   : '',
                },
                {
                        id      : '136761',
                        label   : '',
                },
                {
                        id      : '536119',
                        label   : '',
                },
                {
                        id      : '136853',
                        label   : '',
                },
                {
                        id      : '536123',
                        label   : '',
                },
                {
                        id      : '136657',
                        label   : '',
                },
                {
                        id      : '136951',
                        label   : '',
                },
                {
                        id      : '136665',
                        label   : '',
                },
                {
                        id      : '136949',
                        label   : '',
                },
                {
                        id      : '136719',
                        label   : '',
                },
                {
                        id      : '136895',
                        label   : '',
                },
                {
                        id      : '136667',
                        label   : '',
                },
                {
                        id      : '136937',
                        label   : '',
                },
                {
                        id      : '136631',
                        label   : '',
                },
                {
                        id      : '136903',
                        label   : '',
                },
                {
                        id      : '136691',
                        label   : '',
                },
                {
                        id      : '136893',
                        label   : '',
                },
                {
                        id      : '136709',
                        label   : '',
                },
                {
                        id      : '136669',
                        label   : '',
                },
                {
                        id      : '136717',
                        label   : '',
                },
                {
                        id      : '136927',
                        label   : '',
                },
                {
                        id      : '136869',
                        label   : '',
                },
                {
                        id      : '136923',
                        label   : '',
                },
                {
                        id      : '766032',
                        label   : '',
                },
                {
                        id      : '766130',
                        label   : '',
                },
                {
                        id      : '136813',
                        label   : '',
                },
                {
                        id      : '136933',
                        label   : '',
                },
                {
                        id      : '136707',
                        label   : '',
                },
                {
                        id      : '136935',
                        label   : '',
                },
                {
                        id      : '136831',
                        label   : '',
                },
                {
                        id      : '136925',
                        label   : '',
                },
                {
                        id      : '136823',
                        label   : '',
                },
                {
                        id      : '136931',
                        label   : '',
                },
                {
                        id      : '136637',
                        label   : '',
                },
                {
                        id      : '136683',
                        label   : '',
                },
                {
                        id      : '136807',
                        label   : '',
                },
                {
                        id      : '136967',
                        label   : '',
                },
                {
                        id      : '136759',
                        label   : '',
                },
                {
                        id      : '136993',
                        label   : '',
                },
                {
                        id      : '136861',
                        label   : '',
                },
                {
                        id      : '136917',
                        label   : '',
                },
                {
                        id      : '136653',
                        label   : '',
                },
                {
                        id      : '505911',
                        label   : '',
                },
                {
                        id      : '136661',
                        label   : '',
                },
                {
                        id      : '136899',
                        label   : '',
                },
                {
                        id      : '136827',
                        label   : '',
                },
                {
                        id      : '506139',
                        label   : '',
                },
                {
                        id      : '536115',
                        label   : '',
                },
                {
                        id      : '536121',
                        label   : '',
                },
                {
                        id      : '136839',
                        label   : '',
                },
                {
                        id      : '136909',
                        label   : '',
                },
                {
                        id      : '136865',
                        label   : '',
                },
                {
                        id      : '136969',
                        label   : '',
                },
                {
                        id      : '136633',
                        label   : '',
                },
                {
                        id      : '136907',
                        label   : '',
                },
                {
                        id      : '136649',
                        label   : '',
                },
                {
                        id      : '136971',
                        label   : '',
                },
                {
                        id      : '136945',
                        label   : '',
                },
                {
                        id      : '393462',
                        label   : '',
                },
                {
                        id      : '136625',
                        label   : '',
                },
                {
                        id      : '136921',
                        label   : '',
                },
                {
                        id      : '136833',
                        label   : '',
                },
                {
                        id      : '136819',
                        label   : '',
                },
                {
                        id      : '136701',
                        label   : '',
                },
                {
                        id      : '136959',
                        label   : '',
                },
                {
                        id      : '136961',
                        label   : '',
                },
                {
                        id      : '136743',
                        label   : '',
                },
                {
                        id      : '136775',
                        label   : '',
                },
                {
                        id      : '136687',
                        label   : '',
                },
                {
                        id      : '136955',
                        label   : '',
                },
                {
                        id      : '506342',
                        label   : '',
                },
                {
                        id      : '136829',
                        label   : '',
                },
                {
                        id      : '136781',
                        label   : '',
                },
                {
                        id      : '136795',
                        label   : '',
                },
                {
                        id      : '533450',
                        label   : '',
                },
                {
                        id      : '136705',
                        label   : '',
                },
                {
                        id      : '136815',
                        label   : '',
                },
                {
                        id      : '136875',
                        label   : '',
                },
                {
                        id      : '136887',
                        label   : '',
                },
                {
                        id      : '136779',
                        label   : '',
                },
                {
                        id      : '136755',
                        label   : '',
                },
                {
                        id      : '136789',
                        label   : '',
                },
                {
                        id      : '136699',
                        label   : '',
                },
                {
                        id      : '136793',
                        label   : '',
                },
                {
                        id      : '136689',
                        label   : '',
                },
                {
                        id      : '136651',
                        label   : '',
                },
                {
                        id      : '136765',
                        label   : '',
                },
                {
                        id      : '136835',
                        label   : '',
                },
                {
                        id      : '136837',
                        label   : '',
                },
                {
                        id      : '136871',
                        label   : '',
                },
                {
                        id      : '136685',
                        label   : '',
                },
                {
                        id      : '136797',
                        label   : '',
                },
                {
                        id      : '136769',
                        label   : '',
                },
                {
                        id      : '136799',
                        label   : '',
                },
                {
                        id      : '670311',
                        label   : '',
                },
                {
                        id      : '136809',
                        label   : '',
                },
                {
                        id      : '136867',
                        label   : '',
                },
                {
                        id      : '136783',
                        label   : '',
                },
                {
                        id      : '136863',
                        label   : '',
                },
                {
                        id      : '136947',
                        label   : '',
                },
                {
                        id      : '136751',
                        label   : '',
                },
                {
                        id      : '136889',
                        label   : '',
                },
                {
                        id      : '136877',
                        label   : '',
                },
                {
                        id      : '136713',
                        label   : '',
                },
                {
                        id      : '136849',
                        label   : '',
                },
                {
                        id      : '136855',
                        label   : '',
                },
                {
                        id      : '136735',
                        label   : '',
                },
                {
                        id      : '136747',
                        label   : '',
                },
                {
                        id      : '136811',
                        label   : '',
                },
                {
                        id      : '136847',
                        label   : '',
                },
                {
                        id      : '136749',
                        label   : '',
                },
                {
                        id      : '136773',
                        label   : '',
                },
                {
                        id      : '136677',
                        label   : '',
                },
                {
                        id      : '136767',
                        label   : '',
                },
                {
                        id      : '136883',
                        label   : '',
                },
                {
                        id      : '136857',
                        label   : '',
                },
                {
                        id      : '136731',
                        label   : '',
                },
                {
                        id      : '136771',
                        label   : '',
                },
                {
                        id      : '136745',
                        label   : '',
                },
                {
                        id      : '136785',
                        label   : '',
                },
                {
                        id      : '136803',
                        label   : '',
                },
                {
                        id      : '136727',
                        label   : '',
                },
                {
                        id      : '136879',
                        label   : '',
                },
                {
                        id      : '136845',
                        label   : '',
                },
                {
                        id      : '136787',
                        label   : '',
                },
                {
                        id      : '136841',
                        label   : '',
                },
                {
                        id      : '136825',
                        label   : '',
                },
                {
                        id      : '136721',
                        label   : '',
                },
                {
                        id      : '136801',
                        label   : '',
                },
                {
                        id      : '136757',
                        label   : '',
                },
                {
                        id      : '136881',
                        label   : '',
                },
                {
                        id      : '136805',
                        label   : '',
                },
                {
                        id      : '136675',
                        label   : '',
                },
                {
                        id      : '136859',
                        label   : '',
                },
                {
                        id      : '136723',
                        label   : '',
                },
                {
                        id      : '136843',
                        label   : '',
                },
                {
                        id      : '136711',
                        label   : '',
                },
                {
                        id      : '136791',
                        label   : '',
                },
                {
                        id      : '136697',
                        label   : '',
                },
                {
                        id      : '136739',
                        label   : '',
                },
                {
                        id      : '136729',
                        label   : '',
                },
                {
                        id      : '136715',
                        label   : '',
                },
                {
                        id      : '136725',
                        label   : '',
                },
                {
                        id      : '136777',
                        label   : '',
                },
                {
                        id      : '136753',
                        label   : '',
                },
                {
                        id      : '136695',
                        label   : '',
                },
                {
                        id      : '136821',
                        label   : '',
                },
                {
                        id      : '136703',
                        label   : '',
                },
                {
                        id      : '136851',
                        label   : '',
                },
                ],
            },
            // {
            //     id      : '28044',
            //     label   : 'بازار فارکس ',
            //     info    : ' آخرین نرخ ها و نوسانات بازار جهانی فارکس',
            //     type    : 'folder',
            //     icon    : 'uil uil-usd-circle',
            //     img    : 'currency(1).svg',
            //     show_static: true,
            //     static_items: [
            //         {
            //             id      : 'diff_eur_usd_ask',
            //             label   : 'یورو / دلار',
            //         },
            //         {
            //             id      : 'diff_gbp_usd_ask',
            //             label   : 'پوند / دلار',
            //         },
            //         {
            //             id      : 'diff_usd_cad_ask',
            //             label   : 'دلار / دلار کانادا',
            //         },
            //         {
            //             id      : 'diff_usd_jpy_ask',
            //             label   : 'دلار / ین ژاپن',
            //         },
            //         {
            //             id      : 'diff_usd_chf_ask',
            //             label   : 'دلار / فرانک سوییس',
            //         }
            //     ],
            // },
            {
                id      : '6925',
                label   : 'شاخص‌های بورس',
                info    : ' آخرین نرخ ها و نوسانات شاخص‌های بورس',
                type    : 'folder',
                icon    : 'uil uil-usd-circle',
                img    : 'currency(1).svg',
                show_static: true,
                static_items: [
                    {
                        id      : '6803',
                        label   : '',
                    },
                ],
            },
            {
                id      : '29907',
                label   : 'بازارهای سهام',
                info    : ' آخرین نرخ ها و نوسانات بازارهای سهام کشورها',
                type    : 'folder',
                icon    : 'uil uil-file-graph',
                img    : 'stock(1).svg',
                static_items: [
                    {
                        id      : '441042',
                        label   : '',
                    },
                    {
                        id      : '441046',
                        label   : '',
                    },
                    {
                        id      : '441044',
                        label   : '',
                    },
                    {
                        id      : '441043',
                        label   : '',
                    },
                    {
                        id      : '441045',
                        label   : '',
                    },
                    {
                        id      : '441257',
                        label   : '',
                    },
                    {
                        id      : '441048',
                        label   : '',
                    },
                    {
                        id      : '441053',
                        label   : '',
                    },
                    {
                        id      : '441058',
                        label   : '',
                    },
                    {
                        id      : '441051',
                        label   : '',
                    },
                    {
                        id      : '441082',
                        label   : '',
                    },
                    {
                        id      : '441093',
                        label   : '',
                    },
                    {
                        id      : '441079',
                        label   : '',
                    },
                    {
                        id      : '448577',
                        label   : '',
                    },
                    {
                        id      : '533164',
                        label   : '',
                    },
                    {
                        id      : '441078',
                        label   : '',
                    },
                    {
                        id      : '441091',
                        label   : '',
                    },
                    {
                        id      : '441077',
                        label   : '',
                    },
                    {
                        id      : '441050',
                        label   : '',
                    },
                    {
                        id      : '441100',
                        label   : '',
                    },
                    {
                        id      : '441089',
                        label   : '',
                    },
                    {
                        id      : '448310',
                        label   : '',
                    },
                    {
                        id      : '441133',
                        label   : '',
                    },
                    {
                        id      : '441067',
                        label   : '',
                    },
                    {
                        id      : '448734',
                        label   : '',
                    },
                    {
                        id      : '441147',
                        label   : '',
                    },
                    {
                        id      : '441074',
                        label   : '',
                    },
                    {
                        id      : '441109',
                        label   : '',
                    },
                    {
                        id      : '441114',
                        label   : '',
                    },
                    {
                        id      : '441135',
                        label   : '',
                    },
                    {
                        id      : '441096',
                        label   : '',
                    },
                    {
                        id      : '441066',
                        label   : '',
                    },
                    {
                        id      : '441167',
                        label   : '',
                    },
                    {
                        id      : '408857',
                        label   : '',
                    },
                    {
                        id      : '441065',
                        label   : '',
                    },
                    {
                        id      : '441094',
                        label   : '',
                    },
                    {
                        id      : '441061',
                        label   : '',
                    },
                    {
                        id      : '448581',
                        label   : '',
                    },
                    {
                        id      : '492280',
                        label   : '',
                    },
                    {
                        id      : '441138',
                        label   : '',
                    },
                    {
                        id      : '449634',
                        label   : '',
                    },
                    {
                        id      : '441150',
                        label   : '',
                    },
                ],
            },
            {
                id      : '6819',
                label   : 'صندوق‌های معاملاتی',
                info    : 'آخرین نرخ ها و نوسانات صندوق های معاملاتی کشورها',
                type    : 'folder',
                icon    : 'uil uil-boombox',
                img    : 'fund.svg',
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'آمریکا',
                    },
                    {
                        id      : '726056',
                        label   : '',
                    },
                    {
                        id      : '726073',
                        label   : '',
                    },
                    {
                        id      : '726172',
                        label   : '',
                    },
                    {
                        id      : '726072',
                        label   : '',
                    },
                    {
                        id      : '726058',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'بریتانیا',
                    },
                    {
                        id      : '725056',
                        label   : '',
                    },
                    {
                        id      : '725591',
                        label   : '',
                    },
                    {
                        id      : '755287',
                        label   : '',
                    },
                    {
                        id      : '725111',
                        label   : '',
                    },
                    {
                        id      : '725058',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'چین',
                    },
                    {
                        id      : '719437',
                        label   : '',
                    },
                    {
                        id      : '719448',
                        label   : '',
                    },
                    {
                        id      : '719442',
                        label   : '',
                    },
                    {
                        id      : '719444',
                        label   : '',
                    },
                    {
                        id      : '795303',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'هند',
                    },
                    {
                        id      : '721451',
                        label   : '',
                    },
                    {
                        id      : '754926',
                        label   : '',
                    },
                    {
                        id      : '721432',
                        label   : '',
                    },
                    {
                        id      : '721429',
                        label   : '',
                    },
                    {
                        id      : '761981',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'آلمان',
                    },
                    {
                        id      : '720233',
                        label   : '',
                    },
                    {
                        id      : '720232',
                        label   : '',
                    },
                    {
                        id      : '720267',
                        label   : '',
                    },
                    {
                        id      : '720249',
                        label   : '',
                    },
                    {
                        id      : '720274',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'ایتالیا',
                    },
                    {
                        id      : '721560',
                        label   : '',
                    },
                    {
                        id      : '721556',
                        label   : '',
                    },
                    {
                        id      : '721558',
                        label   : '',
                    },
                    {
                        id      : '721557',
                        label   : '',
                    },
                    {
                        id      : '721562',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'مالزی',
                    },
                    {
                        id      : '722808',
                        label   : '',
                    },
                    {
                        id      : '722809',
                        label   : '',
                    },
                    {
                        id      : '786541',
                        label   : '',
                    },
                    {
                        id      : '722813',
                        label   : '',
                    },
                    {
                        id      : '786546',
                        label   : '',
                    },
                ],
            },
            {
                id      : '7051',
                label   : 'صندوق‌های سرمایه‌گذاری',
                info    : 'آخرین نرخ ها و نوسانات صندوق های سرمایه گذاری کشورها',
                type    : 'folder',
                icon    : 'uil uil-boombox',
                img    : 'fund.svg',
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'آمریکا',
                    },
                    {
                        id      : '730942',
                        label   : '',
                    },
                    {
                        id      : '730939',
                        label   : '',
                    },
                    {
                        id      : '730941',
                        label   : '',
                    },
                    {
                        id      : '730946',
                        label   : '',
                    },
                    {
                        id      : '730947',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'بریتانیا',
                    },
                    {
                        id      : '730844',
                        label   : '',
                    },
                    {
                        id      : '730845',
                        label   : '',
                    },
                    {
                        id      : '730855',
                        label   : '',
                    },
                    {
                        id      : '730854',
                        label   : '',
                    },
                    {
                        id      : '730853',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'چین',
                    },
                    {
                        id      : '727749',
                        label   : '',
                    },
                    {
                        id      : '727748',
                        label   : '',
                    },
                    {
                        id      : '727754',
                        label   : '',
                    },
                    {
                        id      : '727753',
                        label   : '',
                    },
                    {
                        id      : '727770',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'سوییس',
                    },
                    {
                        id      : '730543',
                        label   : '',
                    },
                    {
                        id      : '730542',
                        label   : '',
                    },
                    {
                        id      : '730560',
                        label   : '',
                    },
                    {
                        id      : '730557',
                        label   : '',
                    },
                    {
                        id      : '730554',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'نروژ',
                    },
                    {
                        id      : '781341',
                        label   : '',
                    },
                    {
                        id      : '729539',
                        label   : '',
                    },
                    {
                        id      : '729549',
                        label   : '',
                    },
                    {
                        id      : '729550',
                        label   : '',
                    },
                    {
                        id      : '729553',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'کانادا',
                    },
                    {
                        id      : '727508',
                        label   : '',
                    },
                    {
                        id      : '727509',
                        label   : '',
                    },
                    {
                        id      : '727512',
                        label   : '',
                    },
                    {
                        id      : '727513',
                        label   : '',
                    },
                    {
                        id      : '727511',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'سنگاپور ',
                    },
                    {
                        id      : '730032',
                        label   : '',
                    },
                    {
                        id      : '730033',
                        label   : '',
                    },
                    {
                        id      : '730035',
                        label   : '',
                    },
                    {
                        id      : '730036',
                        label   : '',
                    },
                    {
                        id      : '730031',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'کره جنوبی',
                    },
                    {
                        id      : '730243',
                        label   : '',
                    },
                    {
                        id      : '730244',
                        label   : '',
                    },
                    {
                        id      : '730245',
                        label   : '',
                    },
                    {
                        id      : '730246',
                        label   : '',
                    },
                    {
                        id      : '730247',
                        label   : '',
                    },

                    {
                        id      : 'breakline',
                        label   : 'عربستان',
                    },
                    {
                        id      : '729978',
                        label   : '',
                    },
                    {
                        id      : '743638',
                        label   : '',
                    },
                    {
                        id      : '729986',
                        label   : '',
                    },
                    {
                        id      : '729974',
                        label   : '',
                    },
                    {
                        id      : '729975',
                        label   : '',
                    },
                ],
            },
            {
                id      : '6860',
                label   : 'بازار اوراق قرضه',
                info    : ' آخرین نرخ ها و نوسانات بازار اوراق قرضه کشورها',
                type    : 'folder',
                icon    : 'uil uil-layer-group',
                img     : 'bond(1).svg',
                default : {
                    id      : 30006,
                    label   : "آمریکا",
                    type    : "folder",
                },
                static_items: [
                    {
                        id      : 'breakline',
                        label   : 'آمریکا',
                    },
                    {
                        id      : '777856',
                        label   : '',
                    },
                    {
                        id      : '777858',
                        label   : '',
                    },
                    {
                        id      : '777863',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'چین',
                    },
                    {
                        id      : '777288',
                        label   : '777290',
                    },
                    {
                        id      : '777290',
                        label   : '',
                    },
                    {
                        id      : '777293',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'ژاپن',
                    },
                    {
                        id      : '777463',
                        label   : '',
                    },
                    {
                        id      : '777466',
                        label   : '',
                    },
                    {
                        id      : '777475',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'روسیه',
                    },
                    {
                        id      : '777688',
                        label   : '',
                    },
                    {
                        id      : '777686',
                        label   : '',
                    },
                    {
                        id      : '777695',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'بریتانیا',
                    },
                    {
                        id      : '777836',
                        label   : '',
                    },
                    {
                        id      : '777838',
                        label   : '',
                    },
                    {
                        id      : '777847',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'فرانسه',
                    },
                    {
                        id      : '777335',
                        label   : '',
                    },
                    {
                        id      : '777338',
                        label   : '',
                    },
                    {
                        id      : '777347',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'استرالیا',
                    },
                    {
                        id      : '777188',
                        label   : '',
                    },
                    {
                        id      : '777190',
                        label   : '',
                    },
                    {
                        id      : '777197',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'آلمان',
                    },
                    {
                        id      : '777353',
                        label   : '',
                    },
                    {
                        id      : '777356',
                        label   : '',
                    },
                    {
                        id      : '777365',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'اسپانیا',
                    },
                    {
                        id      : '777751',
                        label   : '',
                    },
                    {
                        id      : '777754',
                        label   : '',
                    },
                    {
                        id      : '777763',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'ترکیه',
                    },
                    {
                        id      : '777816',
                        label   : '',
                    },
                    {
                        id      : '777819',
                        label   : '',
                    },
                    {
                        id      : '777823',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'برزیل',
                    },
                    {
                        id      : '777251',
                        label   : '',
                    },
                    {
                        id      : '777254',
                        label   : '',
                    },
                    {
                        id      : '777259',
                        label   : '',
                    },
                    {
                        id      : 'breakline',
                        label   : 'آفریقای جنوبی',
                    },
                    {
                        id      : '777733',
                        label   : '',
                    },
                    {
                        id      : '777734',
                        label   : '',
                    },
                    {
                        id      : '777736',
                        label   : '',
                    },
                ],
            },
        ]
    },
}

export default static_market;